<template>
  <v-container>
    <v-row>
      <v-spacer/>
      <v-col>
        <h1>Gallery</h1>
      </v-col>
      <v-spacer/>
    </v-row>

    <router-view></router-view>
  </v-container>
</template>

<script>
export default {}
</script>
