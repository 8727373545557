<template>
  <v-row>
    <v-spacer/>
    <v-col>
      <h2>Concept Art</h2>
    </v-col>
    <v-spacer/>
  </v-row>
</template>

<script>
export default {}
</script>
